#breadcrumbs{
  width: 100%;
  float: left;
  margin: 20px 0;
  > a{
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: #333;
    &:hover{
      color: #005eb8;
      transition: 0.3s;
    }
  }
  .breadselected{
    color: #005eb8;
    text-transform: uppercase;
    font-size: 14px;
  }
}



