.mid-head-wrap {
  width: 100%;
  float: left;
  padding: 15px 0;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;

  .mid-head {
    width: 1280px;
    margin: auto;

    .logo-wrap {
      float: left;
      width: 33%;

      > a > img {
        max-width: 100%;
      }
    }

    .header-actions-wrap {
      float: right;
      width: 32%;
      margin: 18px 0;
      #shopping-cart-wrap {
        float: right;
        position: relative;

        .shopping-cart-icon {
          background: url(/images/icons/cart-icon.png);
          background-size: 23px;
          background-repeat: no-repeat;
          width: 25px;
          height: 20px;
          float: left;

        }

        #shopping-cart-size {
          text-align: center;
          font-size: 16px;
          color: #333;
          text-decoration: none;
          margin-left: 5px;
        }

        .shopping-cart-caption {
          text-decoration: none;
          color: #333;
        }

        .shopping-cart-hover {
          display: none;
          position: absolute;
          width: 300px;
          right: 0;
          border: 1px solid #c1c1c1;
          background: #fff;
          z-index: 9999;
          list-style-type: none;
        }
      }
      .favourite-wrap {
        float: right;

        .favourite {
          float: right;
          padding-left: 30px;
          margin: 0 20px;
          background: url("/images/icons/heart-icon.png");
          background-size: 22px;
          background-repeat: no-repeat;
          background-position: 3px 0;

          > a {
            font-size: 16px;
            color: #333;
            text-decoration: none;
          }
        }


      }
      .account-wrap {
        float: right;
        position: relative;

        .account {
          padding-left: 25px;
          background: url(/images/icons/user-icon.png);
          background-size: 16px;
          background-repeat: no-repeat;
          background-position: 0px 2px;
          .account-hover-wrap {
            display: none;
            position: absolute;
            width: 250px;
            right: 0;
            border: 1px solid #c1c1c1;
            background: #fff;
            z-index: 9999;
            padding: 10px;
            box-sizing: border-box;
            .account-hover-name {
              margin-bottom: 10px;
              font-weight: bold;
              color: #333;
              text-decoration: none;
            }

            .account-hover-list {
              width: 100%;
              float: left;
              margin-top: 5px;
              > a {
                width: 100%;
                float: left;
                text-decoration: none;
                color: #005eb8;
                background: #fff;
                border: 1px solid #005eb8;
                padding: 5px 0px 5px 35px;
                box-sizing: border-box;
                &:first-child {
                  background-image: url("/images/icons/user-icon.png");
                  background-repeat: no-repeat;
                  background-size: 16px;
                  background-position: 9px 7px;
                  &:hover {
                    background-image: url("/images/icons/user-icon-hover.png");
                    background-color: #005eb8;
                    color: #fff;
                    transition: 0.2s;
                  }
                }
                &:nth-child(2) {
                  background-image: url("/images/icons/user-register.png");
                  background-repeat: no-repeat;
                  background-size: 20px;
                  background-position: 9px 7px;
                  border-top: none;
                  &:hover {
                    background-image: url("/images/icons/user-register-hover.png");
                    background-color: #005eb8;
                    color: #fff;
                    transition: 0.2s;
                  }
                }
                &:nth-child(3) {
                  background-image: url("/images/icons/password-icon.png");
                  background-repeat: no-repeat;
                  background-size: 20px;
                  background-position: 7px 6px;
                  border-top: none;
                  &:hover {
                    background-image: url("/images/icons/password-icon-hover.png");
                    background-color: #005eb8;
                    color: #fff;
                    transition: 0.2s;
                  }
                }
                &:nth-child(4) {
                  background-image: url("/images/icons/exit-icon.png");
                  background-repeat: no-repeat;
                  background-size: 20px;
                  background-position: 7px 7px;
                  border-top: none;
                  &:hover {
                    background-image: url("/images/icons/exit-icon-hover.png");
                    background-color: #005eb8;
                    color: #fff;
                    transition: 0.2s;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
