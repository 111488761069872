body {
  color:#444;
  font-size:16px;
  line-height: 24px;
  font-family: "Open Sans", Arial, sans-serif;
}

@font-face {
  font-family: 'Intro';
  src: url('/font/Intro.ttf') format('truetype'), url('/font/Intro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}