.bot-head-wrap{
  width: 100%;
  float: left;
  #mainmenu {
    .menu_l1{
      height: 55px;
      text-align: left;
      margin: 0 auto;
      list-style: none;
      box-sizing: border-box;
      width: 100% !important;
      position: absolute;
      cursor: pointer;
      .item_l1 > a{
        font-size: 16px;
        padding: 15px;
        display: block;
        text-decoration: none;
        color: #333;
        transition: 0.22s;
      }
      .item_l1{
        position: relative;
        float: left;
      }
    }
    .menu_l2{
      list-style: none;
      position: absolute;
      top: 54px;
      left: 0px;
      width: 450px;
      background-color: #4658FF;
      padding: 10px;
      z-index: 99;
      margin: 0;
      display: none;
      > li{
        float: left;
        margin-bottom: 5px;
        padding: 0;
        margin-right: 20px;
        width: 200px;
        > a{
          font-size: 15px;
          font-weight: bold;
          display: block;
          text-decoration: none;
          color: #fff;
          margin-bottom: 5px;
        }
      }
    }
    .menu_l3{
      list-style-type: none;
      > li > a{
        text-decoration: none;
        color: #fff;
        font-size: 14px;
        display: block;
        padding: 5px 5px;
        &:hover{
          color: #4658FF;
          background: #fff;
          transition: 0.22s;
        }
      }
    }
  }
  .custom-menu-wrap-top{
    .custom-menu-wrap{
      width: 1280px;
      margin: auto;
      height: 55px;
      .custom-menu-right{
        float: left;
        height: 55px;
        margin-left: 247px;
      }
    }
  }
  .custom-menu-right > ul > li{
    display: inline-block;
    text-align: center;
    a{
      padding: 16px 17px;
      display: block;
      color: #333;
      text-decoration: none;
    }
  }

  .search-trigger{
    float: right;
    height: 30px;
    width: 30px;
    margin: 12px 0;
    cursor: pointer;
    @at-root {
      .search-overlay{
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background: rgba(255, 255, 255, 0.9) url(/images/icons/x.png) no-repeat top 5px right 5px;
        background-size: 20px;
        padding: 10px;
        box-sizing: border-box;
      }

      .header-search-wrap{
        width: 33%;
        float: left;
        height: 40px;
        margin: auto;
        z-index: 99999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        .header-search-form{
          width: 100%;
          border: 3px solid #333;
          border-radius: 30px;
          float: left;
          padding: 2px;
          box-sizing: border-box;
          .header-search-imput{
            background: none;
            width: 80%;
            float: left;
            border: none;
            height: 36px;
            padding: 0 10px;
            box-sizing: border-box;
            font-size: 20px;
            &::placeholder{
              color: #333;
            }
          }
          .header-search-button{
            background: url(/images/search-512.png) no-repeat right;
            background-size: 25px;
            width: 10%;
            margin-right: 10px;
            height: 40px;
            float: right;
            border: none;
            cursor: pointer;
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }
  }
}
