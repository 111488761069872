.top-head-wrap{
  width: 100%;
  float: left;
  background: #005eb8;
  .top-head{
    width: 1280px;
    height: 24px;
    margin: auto;
    padding: 5px 0;
    > div > span{
      color: #fff;
    }
  }
  .top-head-left{
    float: left;
    width: 40%;
    text-align: left;
    > span:first-child{
      background: url("/images/icons/phone-icon.png");
      background-repeat: no-repeat;
      background-size: 18px;
      background-position: 5px;
      padding: 0 30px;
    }
    > span:nth-child(2){
      background: url("/images/icons/email-icon.png");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 5px;
      padding: 0 30px;
    }
  }
  .top-head-right{
    float: right;
    width: 40%;
    text-align: right;
    > span:first-child{
      padding-right: 10px;
    }
    > span:nth-child(2){
      padding-left: 10px;
    }
  }
}